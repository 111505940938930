import CSelectSearch from '@/components/CSelectSearch'

export default {
  components: { CSelectSearch },
  data() {
    return {
      selectedCompany: +this.$route.params.id || null,
      companiesList: [],
      loadingCompanies: false,
      ignoreSelectingCompany: false,

      mountedComponent: false,
    }
  },
  watch: {
    selectedCompany(companyId) {
      if (this.ignoreSelectingCompany) {
        this.ignoreSelectingCompany = false
        return
      }

      if (!companyId) return

      this.$router
        .push({
          name: 'Company',
          params: {
            id: companyId,
          },
        })
        .catch(() => {
          this.selectedCompany = +this.$route.params.id || null
          this.ignoreSelectingCompany = true
        })
    },
  },
  async mounted() {
    this.mountedComponent = true
    await this.fetchCompaniesList()
  },
  methods: {
    fetchCompaniesList() {
      this.loadingCompanies = true
      this.$http.companies
        .fetchCompanies({
          params: {
            per_page: -1,
            page: 1,
            sort_field: '',
            sort_direction: '',
            with_current: 1,
          },
        })
        .then(({ data }) => {
          this.companiesList = data.data
            ?.map((el) => ({
              value: el.id,
              label: el.name,
            }))
            .sort((a, b) => {
              const _a = a.label?.toUpperCase()
              const _b = b.label?.toUpperCase()

              if (_a > _b) {
                return 1
              }
              if (_a < _b) {
                return -1
              }
              return 0
            })
        })
        .finally(() => {
          this.loadingCompanies = false
        })
    },
  },
}
